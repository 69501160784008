<template>
  <div ref="container"
       data-scroll
  >
    <Menu />

    <slot />

    <Footer />

    <CookieModal v-if="showCookieNotice"
                 @give-consent="setCookieConsent()"
    />
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { useRoute } from 'vue-router';

// add global class based on current route
const container = ref();
const route = useRoute();
watch(
  () => route.path,
  async newPath => {
    addRouteClass(newPath);
  }
);

const addRouteClass = (path: string) => {
  const page = path === '/' ? 'homepage' : path.split('/')[1];
  if (container.value) container.value.className = page;
};

watch(
  () => container.value,
  async el => {
    addRouteClass(route.path);
  }
);

addRouteClass(route.path);

// Test if browser supports LocalStorage
function localStorageAvailable() {
  if (process.client) {
    try {
      const x = '__storage_test__';
      localStorage.setItem(x, x);
      localStorage.removeItem(x);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

const showCookieNotice = computed(() => {
  if (process.client && localStorageAvailable() && localStorage.getItem('consent') === 'true') return false;
  else return true;
});

function setCookieConsent() {
  if (process.client && localStorageAvailable()) localStorage.setItem('consent', 'true');
}
</script>
